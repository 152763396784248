import { useSearchParams } from "react-router-dom";
import { initSettingsDlst } from "../../data/initSetting";
import { getCustomProperty, replaceAmpersand } from "../../utils/utils";
import ChatFieldBase from "../Common/ChatFieldBase";
import ChatFieldBaseSDK from "../Common/ChatFieldBaseSDK";

const ChatDailyshotSDK = (props) => {
    const {variant} = props;
    const customProperty = getCustomProperty('dlst');
    let queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    // let floatingDataset = searchParams.get('product');
    // floatingDataset = JSON.parse(floatingDataset);
    // replaceAmpersand(floatingDataset);
    const reqParams = {
        itemId: searchParams.get('i'),
        userId: searchParams.get('u'),
        type: searchParams.get('t'),
        isMobile: searchParams.get('ch'),
        floatingComment: searchParams.get('fc'),
    }

    sessionStorage.setItem('itemId', reqParams.itemId);

    return (
        <ChatFieldBaseSDK
            customProperty={customProperty}
            customSet={initSettingsDlst}
            variant={variant}
            reqParams={reqParams}
        />
    );
};

export default ChatDailyshotSDK;