import { useEffect, useState } from 'react';
import style from '../../style/styles/Message.module.css';
import compStyle from '../../style/styles/Components.module.css';
import thumbDown from '../../images/thumb_down.svg'
import starFill from '../../images/star_filled.png';
import { addComma, getServiceType } from '../../utils/utils';
import { logEvent, sendFeedback } from '../../utils/apis';
import { useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { gtagCarouselClick, gtagCarouselScroll, gtagClickedOption, gtagSendFeedback, gtagTabFeedback } from '../../utils/gtag';

export const CarouselDemo = (props) => {
    let { id, product, dialId, isMobileDevice, carouselEndRef, colorInput = undefined, recommendSizeInput = undefined } = props;
    let [fbModal, setFbModal] = useState(false);
    let [fbState, setFbState] = useState(false);
    let [isScrolled, setIsScrolled] = useState(false);

    const primColor = colorInput ? colorInput.color0 : '#154cca';
    const roomId = useParams().demoId;
    const fbContents = [
        '요청한 상품과 관련이 없는 상품이에요',
        '관련된 상품이지만 원하던 상품이 아니에요',
        '챗봇의 상품 정보가 실제와 달라요',
        '아니에요, 마음에 들어요!',
    ]

    const handleFeedback = (e, idx) => {
        e.stopPropagation();
        e.preventDefault();
        const product = JSON.parse(e.currentTarget.dataset.product);
        setFbModal(idx + 1);

        // gtag for TabFeedback
        gtagTabFeedback(product.itemId);
    }

    const handleFeedbackBtn = (e, idx) => {
        e.stopPropagation();
        const product = JSON.parse(e.currentTarget.dataset.product);
        const dialId = e.currentTarget.dataset.dialid;
        sendFeedback(roomId, 'demo', fbContents[idx], dialId, product.itemId);
        setFbState(true);

        // gtag for SendFeedback
        gtagSendFeedback(product.itemId);
    }

    useEffect(() => {
        setTimeout(function () {
            if (fbState) {
                setFbState(false);
                setFbModal(false);
            }
        }, 1500);
    }, [fbState])

    // gtag for CarouselScroll
    const throttledHandleCarouselScroll = _.throttle(() => {
        const carouselElem = document.getElementById('carousel-container');
        const carouselWidth = carouselElem?.scrollWidth;
        const halfCarouselWidth = carouselWidth * 0.2;
        const scrollAmount = carouselElem?.scrollLeft;
        if (isScrolled) return;
        if (scrollAmount > halfCarouselWidth) {
            gtagCarouselScroll(product[0].itemId);

            setIsScrolled(true);
            carouselElem?.removeEventListener('scroll', throttledHandleCarouselScroll);
        }
    }, 1000)

    const handleCarouselScroll = () => {
        document.getElementById('carousel-container')?.addEventListener('scroll', throttledHandleCarouselScroll);
    };

    handleCarouselScroll();

    return (
        <div id='carousel-container' className={isMobileDevice ? style.Carousel__container__md : style.Carousel__container}>
            <div style={{ position: 'relative' }}>
                <div
                    className={style.Carousel__flexBox}
                    style={{ '--justify-content': 'flex-start' }}
                >
                    {
                        product?.map((data, idx) => {
                            return (
                                <div key={idx}>
                                    {
                                        fbModal === idx + 1 &&
                                        <div
                                            className={style.Carousel__feedbackModal}
                                            style={{ left: 174 * idx + 'px' }}
                                        >
                                            {
                                                (fbState) ?
                                                    <div>
                                                        <p className='h7'>피드백이 전달 되었어요, 감사합니다!</p>
                                                    </div> :
                                                    <>
                                                        <p className='h7'>💬 상품 추천이 마음에 들지 않았던 이유를 알려주세요!</p>
                                                        {
                                                            fbContents.map((content, idx) => {
                                                                return (
                                                                    <button
                                                                        key={idx}
                                                                        className='feedback-selections'
                                                                        data-dialid={dialId}
                                                                        data-product={JSON.stringify(data)}
                                                                        onClick={(e) => { handleFeedbackBtn(e, idx) }}
                                                                    >
                                                                        <p className='h7'>{content}</p>
                                                                    </button>
                                                                )
                                                            })
                                                        }
                                                    </>
                                            }
                                        </div>
                                    }

                                    <div
                                        key={idx}
                                        className={style.Carousel__wrap}
                                        style={{ '--point-color': (idx > 0 ? 'none' : primColor), display: ((recommendSizeInput === 'single' && idx > 0) && 'none') }}
                                    >
                                        <div className={style.Carousel__topWrap}>
                                            <div
                                                className={style.Carousel__imgWrap}
                                                style={{ '--height': '150px' }}
                                            >
                                                <div style={{ position: 'absolute', width: '150px', height: '150px' }}>
                                                    <button
                                                        className={`${style.Carousel__fbBtn} feedback-btn`}
                                                        data-dialid={dialId}
                                                        data-product={JSON.stringify(data)}
                                                        onClick={(e) => { handleFeedback(e, idx) }}
                                                    >
                                                        <img className='feedback-btn' src={thumbDown} />
                                                    </button>
                                                </div>
                                                <div
                                                    data-idx={idx}
                                                    className={style.Carousel__Img}
                                                    style={{
                                                        '--background-image': `url(${data.imageUrl})`,
                                                        '--width': '150px',
                                                        '--height': '150px',
                                                    }}
                                                />
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <a href={data.productUrl} style={{ width: '100%' }}>
                                                <div className={style.Carousel__contentWrap}>
                                                    <div>
                                                        <div
                                                            className={idx === 0 ? compStyle.Label__badge__recommended : compStyle.Label__badge__related}
                                                            style={{ '--point-color': (idx > 0 ? '#999' : primColor), display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                        >
                                                            <p className='h10'>
                                                                {idx === 0 ? '추천' : '관련'}
                                                            </p>
                                                        </div>
                                                        <div className={compStyle.Spacing__4} />
                                                        <p className='h5'
                                                            style={{
                                                                minHeight: '34px',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {data.name}
                                                        </p>
                                                    </div>
                                                    <div style={{ minHeight: '14px' }} />
                                                    <p className='h1'>
                                                        {addComma(data.price)}원
                                                    </p>
                                                    <div className={compStyle.Spacing__4} />
                                                    <div
                                                        className={style.Carousel__flexBox}
                                                        style={{ '--align-items': 'center', '--height': '15px' }}
                                                    >
                                                        <span style={{ width: '100%', height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                                            <img src={starFill} width={14} />
                                                        </span>
                                                        <div style={{ minWidth: '2px' }} />
                                                        <p className='h9' style={{ color: '#999999' }}>
                                                            {isNaN(data.rate) ? data.rate : data.rate.toFixed(1)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={compStyle.Spacing__8} />
                                                <div className={style.Carousel__bottomWrap}>
                                                    <div className={style.Carousel__desc}>
                                                        <p
                                                            className='h8'
                                                            style={{
                                                                color: '#c0c0c0',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 1,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {data.how}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={compStyle.Spacing__16} />
            <div ref={carouselEndRef} />
        </div>
    )
}

export const CarouselDemoHori = (props) => {
    let { id, product, dialId, isMobileDevice, carouselEndRef, colorInput = undefined, recommendSizeInput = undefined } = props;
    let [fbModal, setFbModal] = useState(false);
    let [fbState, setFbState] = useState(false);
    let [isScrolled, setIsScrolled] = useState(false);

    const primColor = colorInput ? colorInput.color0 : '#154cca';
    const roomId = useParams().demoId;
    const fbContents = [
        '요청한 상품과 관련이 없는 상품이에요',
        '관련된 상품이지만 원하던 상품이 아니에요',
        '챗봇의 상품 정보가 실제와 달라요',
        '아니에요, 마음에 들어요!',
    ]

    const handleFeedback = (e, idx) => {
        e.stopPropagation();
        e.preventDefault();
        const product = JSON.parse(e.currentTarget.dataset.product);
        setFbModal(idx + 1);

        // gtag for TabFeedback
        gtagTabFeedback(product.itemId);
    }

    const handleFeedbackBtn = (e, idx) => {
        e.stopPropagation();
        const product = JSON.parse(e.currentTarget.dataset.product);
        const dialId = e.currentTarget.dataset.dialid;
        sendFeedback(roomId, 'ckat', fbContents[idx], dialId, product.itemId);
        setFbState(true);

        // gtag for SendFeedback
        gtagSendFeedback(product.itemId);
    }

    useEffect(() => {
        setTimeout(function () {
            if (fbState) {
                setFbState(false);
                setFbModal(false);
            }
        }, 1500);
    }, [fbState])

    // gtag for CarouselScroll
    const throttledHandleCarouselScroll = _.throttle(() => {
        const carouselElem = document.getElementById('carousel-container');
        const carouselWidth = carouselElem?.scrollWidth;
        const halfCarouselWidth = carouselWidth * 0.2;
        const scrollAmount = carouselElem?.scrollLeft;
        if (isScrolled) return;
        if (scrollAmount > halfCarouselWidth) {
            gtagCarouselScroll(product[0].itemId);

            setIsScrolled(true);
            carouselElem?.removeEventListener('scroll', throttledHandleCarouselScroll);
        }
    }, 1000)

    const handleCarouselScroll = () => {
        document.getElementById('carousel-container')?.addEventListener('scroll', throttledHandleCarouselScroll);
    };

    handleCarouselScroll();

    return (
        <div id='carousel-container' className={isMobileDevice ? style.Carousel__container__md : style.Carousel__container}>
            <div style={{ position: 'relative' }}>
                <div
                    className={style.Carousel__Hori__flexBox}
                    style={{ '--justify-content': 'flex-start' }}
                >
                    {
                        product?.map((data, idx) => {
                            return (
                                <div key={idx} className={(recommendSizeInput === 'single' && idx > 0) && 'hide'}>
                                    {
                                        fbModal === idx + 1 &&
                                        <div
                                            className={style.Carousel__feedbackModal}
                                            style={{ left: 174 * idx + 'px' }}
                                        >
                                            {
                                                (fbState) ?
                                                    <div>
                                                        <p className='h7'>피드백이 전달 되었어요, 감사합니다!</p>
                                                    </div> :
                                                    <>
                                                        <p className='h7'>💬 상품 추천이 마음에 들지 않았던 이유를 알려주세요!</p>
                                                        {
                                                            fbContents.map((content, idx) => {
                                                                return (
                                                                    <button
                                                                        key={idx}
                                                                        className='feedback-selections'
                                                                        data-dialid={dialId}
                                                                        data-product={JSON.stringify(data)}
                                                                        onClick={(e) => { handleFeedbackBtn(e, idx) }}
                                                                    >
                                                                        <p className='h7'>{content}</p>
                                                                    </button>
                                                                )
                                                            })
                                                        }
                                                    </>
                                            }
                                        </div>
                                    }

                                    <div
                                        key={idx}
                                        className={style.Carousel__Hori__wrap}
                                        style={{ '--point-color': (idx > 0 ? 'none' : primColor), display: ((recommendSizeInput === 'single' && idx > 0) && 'none') }}
                                    >
                                        <div className={style.Carousel__Hori__topWrap}>
                                            <div
                                                className={style.Carousel__Hori__imgWrap}
                                            >
                                                <div style={{ position: 'absolute', width: '105px', height: '140px' }}>
                                                    <button
                                                        className={`${style.Carousel__Hori__fbBtn} feedback-btn`}
                                                        data-dialid={dialId}
                                                        data-product={JSON.stringify(data)}
                                                        onClick={(e) => { handleFeedback(e, idx) }}
                                                    >
                                                        <img className='feedback-btn' src={thumbDown} />
                                                    </button>
                                                </div>
                                                <div
                                                    data-idx={idx}
                                                    className={style.Carousel__Hori__Img}
                                                    style={{
                                                        '--background-image': `url(${data.imageUrl})`,
                                                        '--minWidth': '105px',
                                                        '--minHeight': '140px',
                                                    }}
                                                />
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <a href={data.productUrl}>
                                                <div className={style.Carousel__contentWrap}>
                                                    <div>
                                                        <div
                                                            className={idx === 0 ? compStyle.Label__badge__recommended : compStyle.Label__badge__related}
                                                            style={{ '--point-color': (idx > 0 ? '#999' : primColor), display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                        >
                                                            <p className='h10'>
                                                                {idx === 0 ? '추천' : '관련'}
                                                            </p>
                                                        </div>
                                                        <div className={compStyle.Spacing__4} />
                                                        <p className='h5'
                                                            style={{
                                                                maxWidth: '141px',
                                                                minHeight: '34px',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {data.name}
                                                        </p>
                                                    </div>
                                                    <div style={{ minHeight: '14px' }} />
                                                    <p className='h1'>
                                                        {addComma(data.price)}원
                                                    </p>
                                                    <div className={compStyle.Spacing__4} />
                                                    <div
                                                        className={style.Carousel__flexBox}
                                                        style={{ '--align-items': 'center', '--height': '15px' }}
                                                    >
                                                        <span style={{ width: '100%', height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                                            <img src={starFill} width={14} />
                                                        </span>
                                                        <div style={{ minWidth: '2px' }} />
                                                        <p className='h9' style={{ color: '#999999' }}>
                                                            {isNaN(data.rate) ? data.rate : data.rate.toFixed(1)}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={compStyle.Spacing__8} />
                                                <div className={style.Carousel__bottomWrap}>
                                                    <div className={style.Carousel__desc}>
                                                        <p
                                                            className='h8'
                                                            style={{
                                                                color: '#c0c0c0',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 1,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {data.how}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={compStyle.Spacing__16} />
            <div ref={carouselEndRef} />
        </div>
    )
}

export const CarouselDlst = (props) => {
    const { id, product, isMobileDevice, carouselEndRef, clientId, userGroup } = props;
    const primColor = (clientId === 'dlst') ? '#FE5000' : '#154CCA';

    const handleCarouselClicked = (e, name, idx) => {
        gtagCarouselClick(idx, name, clientId, userGroup);
        gtagClickedOption(id, idx, name, clientId, userGroup);
    }

    return (
        <div className={isMobileDevice ? style.Carousel__container__md : style.Carousel__container}>
            <div
                className={style.Carousel__flexBox}
                style={{ '--justify-content': 'flex-start' }}
            >
                {
                    product?.map((data, idx) => {
                        return (
                            <a key={idx} href={data.productUrl} style={{ width: '100%' }}>
                                <div
                                    key={idx}
                                    className={style.Carousel__wrap}
                                    style={{ '--point-color': (idx > 0 ? 'none' : primColor) }}
                                    onClick={(e) => {handleCarouselClicked(e, data.name, idx)}}
                                >
                                    <div className={style.Carousel__topWrap}>
                                        <div
                                            className={style.Carousel__imgWrap}
                                            style={{ '--height': '150px' }}
                                        >
                                            <div
                                                data-idx={idx}
                                                className={style.Carousel__Img}
                                                style={{
                                                    '--background-image': `url(${data.imageUrl})`,
                                                    '--width': '150px',
                                                    '--height': '150px'
                                                }}
                                            />
                                        </div>
                                        <div className={compStyle.Spacing__8} />
                                        <div className={style.Carousel__contentWrap}>
                                            <div>
                                                <div
                                                    className={idx === 0 ? compStyle.Label__badge__recommended : compStyle.Label__badge__related}
                                                    style={{ '--point-color': (idx > 0 ? '#999' : primColor), display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                >
                                                    <p className='h10'>
                                                        {idx === 0 ? '추천' : '관련'}
                                                    </p>
                                                </div>
                                                <div className={compStyle.Spacing__4} />
                                                <p className='h5'
                                                    style={{
                                                        minHeight: '34px',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 2,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {data.name}
                                                </p>
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <div
                                                className={style.Carousel__flexBox}
                                                style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                            >
                                                {
                                                    data.discount !== 0 ?
                                                        <div>
                                                            <p
                                                                className='h9'
                                                                style={{ textDecoration: "line-through", color: "#c0c0c0" }}
                                                            >
                                                                {addComma(data.originPrice)}
                                                            </p>
                                                            <div
                                                                className={style.Carousel__flexBox}
                                                                style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                            >
                                                                <p className='h1' style={{ color: "#ec3e48" }}> {data.discount}%</p>
                                                                <div className={compStyle.Spacing__4} />
                                                                <p className='h1'>{addComma(data.price)}원</p>
                                                            </div>
                                                        </div> :
                                                        <div>
                                                            <div style={{ minHeight: '14px' }} />
                                                            <p className='h1'>
                                                                {addComma(data.price)}원
                                                            </p>
                                                        </div>
                                                }
                                            </div>
                                            <div className={compStyle.Spacing__4} />
                                            <div
                                                className={style.Carousel__flexBox}
                                                style={{ '--align-items': 'center', '--height': '15px' }}
                                            >
                                                <span style={{ width: '100%', height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                                    <img src={starFill} width={14} />
                                                </span>
                                                <div style={{ minWidth: '2px' }} />
                                                <p className='h9' style={{ color: '#999999', minWidth: '30px' }}>
                                                    {data.rate.toFixed(1) < 0.1 ? '신규' : data.rate.toFixed(1)}
                                                </p>
                                            </div>
                                        </div>
                                        <div className={compStyle.Spacing__8} />
                                        <div className={style.Carousel__bottomWrap}>
                                            <div className={style.Carousel__desc}>
                                                <p
                                                    className='h8'
                                                    style={{
                                                        color: '#c0c0c0',
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 1,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden'
                                                    }}
                                                >
                                                    {data.shortDesc}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        )
                    })
                }
            </div>
            <div className={compStyle.Spacing__16} />
            <div ref={carouselEndRef} />
        </div>
    )
}

export const CarouselDlstHori = (props) => {
    let { id, product, dialId, isMobileDevice, carouselEndRef, colorInput = undefined, recommendSizeInput = undefined, clientId, userGroup, setGenExam, fetchExample, productIdx, setProductIdx, userEventURL, apiKey } = props;
    let [isScrolled, setIsScrolled] = useState(false);
    let queryString = window.location.search;

    let isDragging = false;
    let startPosition = 0;
    let currentTranslate = 0;
    let prevTranslate = 0;
    const cardWidth = 280;

    const primColor = colorInput ? colorInput.color0 : '#154cca';
    const roomId = useParams().demoId;
    const isRedirectEnabled = (clientId !== 'viml');
    const dummyExam = [
        '비슷한 상품 추천 받기',
        '사용자 리뷰',
        '상품 특장점'
    ]

    // carousel dragging
    const carousel = document.getElementById('carousel');

    // carousel?.addEventListener('mousedown', e => {
    //     e.stopPropagation()
    //     isDragging = true;
    //     startPosition = e.clientX;
    //     prevTranslate = currentTranslate;
    //     carousel.style.transition = 'none';
    // });

    // carousel?.addEventListener('mousemove', e => {
    //     e.stopPropagation()
    //     if (isDragging) {
    //         const currentPosition = e.clientX;
    //         currentTranslate = prevTranslate + currentPosition - startPosition;
    //         carousel.style.transform = `translateX(${currentTranslate}px)`;
    //     }
    // });

    // carousel?.addEventListener('mouseup', e => {
    //     e.stopPropagation();
    //     isDragging = false;
    //     const threshold = cardWidth * 0.3;
    //     if (Math.abs(currentTranslate - prevTranslate) > threshold) {
    //         if (currentTranslate > prevTranslate) {
    //             navigateCarousel('prev', Math.abs(currentTranslate + prevTranslate) / cardWidth);
    //         } else {
    //             navigateCarousel('next', Math.abs(currentTranslate - prevTranslate) / cardWidth);
    //         }
    //     }
    //     carousel.style.transition = 'transform 0.1s ease-in-out';

    // });

    // carousel?.addEventListener('mouseleave', (e) => {
    //     e.stopPropagation()
    //     if (isDragging) {
    //         const threshold = cardWidth * 0.3;
    //         if (Math.abs(currentTranslate - prevTranslate) > threshold) {
    //             if (currentTranslate > prevTranslate) {
    //                 navigateCarousel('prev', Math.abs(currentTranslate - prevTranslate) / cardWidth);
    //             } else {
    //                 navigateCarousel('next', Math.abs(currentTranslate - prevTranslate) / cardWidth);
    //             }
    //         }
    //         carousel.style.transition = 'transform 0.1s ease-in-out';
    //     }
    //     isDragging = false;
    // });

    function navigateCarousel(direction, multiple) {
        if (direction === 'next') {
            currentTranslate += cardWidth;
            if (currentTranslate > carousel.offsetWidth / 2) currentTranslate = -carousel.offsetWidth;
        } else {
            currentTranslate -= (cardWidth * (multiple));
            if (currentTranslate > -carousel.offsetWidth / 2) {
                currentTranslate = 0
            };
        }
        carousel.style.transform = `translateX(${currentTranslate}px)`;
    }

    // gtag for CarouselScroll
    const throttledHandleCarouselScroll = _.throttle(() => {
        const carouselElem = document.getElementById('carousel-container');
        const carouselWidth = carouselElem?.scrollWidth;
        const halfCarouselWidth = carouselWidth * 0.2;
        const scrollAmount = carouselElem?.scrollLeft;
        if (isScrolled) return;
        if (scrollAmount > halfCarouselWidth) {
            gtagCarouselScroll(product[0].itemId);
            setIsScrolled(true);
            carouselElem?.removeEventListener('scroll', throttledHandleCarouselScroll);
        }
    }, 1000)

    const handleCarouselScroll = () => {
        document.getElementById('carousel-container')?.addEventListener('scroll', throttledHandleCarouselScroll);
    };

    handleCarouselScroll();

    const sendRedirectStatus = (state, url) => {
        const targetWindow = window.parent;
        const redirectState = {
            redirectState: state,
            redirectUrl: url,
        }
        targetWindow.postMessage(redirectState, "*");
    }

    const handleCarouselClicked = (e, name, idx, itemId) => {
        gtagCarouselClick(idx, name, clientId, userGroup);
        gtagClickedOption(id, idx, name, clientId, userGroup);
        if (userEventURL) {
            logEvent(userEventURL, apiKey, 'CarouselClicked', roomId, itemId, clientId, isMobileDevice);
        }
    }

    const [currentPos, setCurrentPos] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollDir, setScrollDir] = useState(null);

    const handleCarouselNext = (e) => {
        e.stopPropagation();
        setCurrentPos(res => {
            if (res - 322 > -322 * product.length) {
                return (res - 322)
            } else {
                return res
            }
        })
    }

    const handleCarouselPrev = (e) => {
        e.stopPropagation();
        setCurrentPos(res => {
            if ((res + 322 < 322 * product.length) && (res + 322 <= 0)) {
                return (res + 322)
            } else {
                return res
            }
        })
    }

    const [prevPosition, setPrevPosition] = useState(null);

    const handleTouchMove = (e) => {
        e.stopPropagation();
        const touch = e.touches[0];
        setPrevPosition(touch);
        if (!prevPosition) return
        const diff = touch.pageY - prevPosition.pageY;
        setScrollPosition(scrollPosition + diff);
        if(diff > 0) setScrollDir('right');
        else if (diff < 0) setScrollDir('left');
        else {setScrollDir(null)};
    }

    const handleTouchEnd = (e) => {
        e.stopPropagation();
        setPrevPosition(null);
        if (scrollDir === 'right') {
            handleCarouselNext(e);
        }
        else {
            handleCarouselPrev(e);
        }
    }

    const clickCarouselCard = (e, productUrl) => {
        e.preventDefault();
        e.stopPropagation();
        if (isMobileDevice) return;
        window.location.href = productUrl;
    }

    

    useEffect(() => {
        const productIdx = currentPos / -322;
        sessionStorage.setItem('itemId', product[productIdx].itemId);
        setProductIdx(productIdx)
        fetchExample(roomId, clientId, product[productIdx].itemId)
            .then(res => {
                if (res.length === 0) setGenExam([dummyExam, product[productIdx].itemId])
                else {setGenExam([res, product[productIdx].itemId])}
            });
    }, [currentPos])

    return (
        <div id='carousel-container' className={style.NavCarousel__Container}>
            <div 
                style={{
                    position: 'relative', 
                    display: 'flex', 
                    width: '100%', 
                    height: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    overflow: 'hidden',
                }}
            >
                <img 
                    src={currentPos === 0 ? '/img/units/carousel-prev-inactive.png' : '/img/units/carousel-prev-active.png'}
                    width={32} height={32} 
                    style={{zIndex: 1, visibility: (currentPos === 0 && 'hidden')}} 
                    onClick={(e) => handleCarouselPrev(e)}
                />
                <div
                    id='carousel'
                    className={style.NavCarousel__CardWrap}
                    style={{transform: `translateX(${currentPos}px)`}}
                    // style={{ '--justify-content': 'flex-start' }}
                    // onTouchMove={handleTouchMove}
                    // onTouchEnd={handleTouchEnd}
                >
                    {
                        product?.map((data, idx) => {
                            return (
                                <div key={idx}>
                                    <div
                                        className={style.NavCarousel__Card}
                                        style={{cursor: 'pointer'}}
                                        onClick={(e) => {
                                            handleCarouselClicked(e, data.name, idx, data.itemId);
                                            sendRedirectStatus(true, data.productUrl);
                                            clickCarouselCard(e, data.productUrl);
                                        }}
                                    >
                                        <div className={style.Carousel__Hori__topWrap}>
                                            <div
                                                className={style.Carousel__Hori__imgWrap}
                                            >
                                                <div
                                                    className={style.Carousel__Hori__Img}
                                                    style={{
                                                        '--background-image': `url(${data.imageUrl})`,
                                                        '--minWidth': '105px',
                                                        '--minHeight': '140px',
                                                    }}
                                                >
                                                    {
                                                        data.vivino_rate.length > 0 &&
                                                        <div className={style.NavCarousel__VivinoWrap}>
                                                            <img src='/img/units/carousel-vivino.png' width={25} height={5} />
                                                            <p className='vivino-font'>{data.vivino_rate}</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            {/* <a href={data.productUrl}> */}
                                                <div 
                                                    className={style.Carousel__contentWrap}
                                                    style={{height: '113px'}}
                                                >
                                                    {/* <div> */}
                                                        {/* <div
                                                            className={compStyle.Label__badge__recommended}
                                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                        > */}
                                                        <span style={{height: '16px'}}>
                                                            <img src={getServiceType(data.serviceType)} height={16} />
                                                        </span>
                                                        {/* </div> */}
                                                        <div className={compStyle.Spacing__4} />
                                                        <p className='h6'
                                                            style={{
                                                                maxWidth: '141px',
                                                                minHeight: '33px',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {data.name}
                                                        </p>
                                                    {/* </div> */}
                                                    <div className={compStyle.Spacing__8} />
                                                    <div
                                                        className={style.Carousel__flexBox}
                                                        style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                    >
                                                        {
                                                            data.discount !== 0 ?
                                                            <div>
                                                                <p
                                                                    className='h9'
                                                                    style={{ textDecoration: "line-through", color: "#c0c0c0" }}
                                                                >
                                                                    {addComma(data.originPrice)}
                                                                </p>
                                                                <div
                                                                    className={style.Carousel__flexBox}
                                                                    style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                                >
                                                                    <p className='h1' style={{ color: "#ec3e48" }}> {data.discount}%</p>
                                                                    <div className={compStyle.Spacing__4} />
                                                                    <p className='h1'>{addComma(data.price)}원</p>
                                                                </div>
                                                            </div> :
                                                            <div>
                                                                <div style={{ minHeight: '14px' }} />
                                                                <p className='h1'>
                                                                    {addComma(data.price)}원
                                                                </p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className={compStyle.Spacing__4} />
                                                    <div
                                                        className={style.Carousel__flexBox}
                                                        style={{ '--align-items': 'center', '--height': '15px' }}
                                                    >
                                                        <span style={{ height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                                            <img src='/img/units/carousel-star-gray.png' width={14} />
                                                        </span>
                                                        <p className='h9' style={{ width: 'fit-content', color: '#999999', wordBreak: 'keep-all' }}>
                                                            {data.rate.toFixed(1) < 0.1 ? '신규' : `${data.rate.toFixed(1)} 리뷰 ${data.reviewNum}개`}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className={compStyle.Spacing__4} />
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    {
                                                        data.tags?.map((tag, idx) => {
                                                            const textColor = tag.text_color;
                                                            const backgroundColor = tag.background_color;
                                                            const borderColor = tag.border_color;
                                                            return (
                                                                <span 
                                                                    className='badge-font'
                                                                    style={{
                                                                        height: '18px',
                                                                        padding: '3px 4px',
                                                                        border: `1px solid ${borderColor}`,
                                                                        borderRadius: '3px',
                                                                        background: `${backgroundColor}`,
                                                                        color: `${textColor}`,
                                                                        marginRight: '4px',
                                                                    }}
                                                                >{tag.name}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            {/* </a> */}
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
                <img 
                    src={currentPos <= -322 * (product.length-1) ? '/img/units/carousel-next-inactive.png' : '/img/units/carousel-next-active.png'}
                    width={32} height={32} 
                    style={{zIndex: 1, visibility: (currentPos <= -322 * (product.length-1) && 'hidden')}} 
                    onClick={(e) => handleCarouselNext(e)}
                />
            </div>
            {/* <div className={compStyle.Spacing__8} /> */}
            <div ref={carouselEndRef} />
        </div>
    )
}

export const CarouselVimlHori = (props) => {
    let { id, product, dialId, isMobileDevice, carouselEndRef, colorInput = undefined, recommendSizeInput = undefined, clientId, userGroup } = props;
    let [isScrolled, setIsScrolled] = useState(false);

    let isDragging = false;
    let startPosition = 0;
    let currentTranslate = 0;
    let prevTranslate = 0;
    const cardWidth = 280;

    const primColor = colorInput ? colorInput.color0 : '#154cca';
    const roomId = useParams().demoId;
    const isRedirectEnabled = (clientId !== 'viml');

    // carousel dragging
    const carousel = document.getElementById('carousel');

    carousel?.addEventListener('mousedown', e => {
        e.stopPropagation()
        isDragging = true;
        startPosition = e.clientX;
        prevTranslate = currentTranslate;
        carousel.style.transition = 'none';
    });

    carousel?.addEventListener('mousemove', e => {
        e.stopPropagation()
        if (isDragging) {
            const currentPosition = e.clientX;
            currentTranslate = prevTranslate + currentPosition - startPosition;
            carousel.style.transform = `translateX(${currentTranslate}px)`;
        }
    });

    carousel?.addEventListener('mouseup', e => {
        e.stopPropagation();
        isDragging = false;
        const threshold = cardWidth * 0.3;
        if (Math.abs(currentTranslate - prevTranslate) > threshold) {
            if (currentTranslate > prevTranslate) {
                navigateCarousel('prev', Math.abs(currentTranslate + prevTranslate) / cardWidth);
            } else {
                navigateCarousel('next', Math.abs(currentTranslate - prevTranslate) / cardWidth);
            }
        }
        carousel.style.transition = 'transform 0.1s ease-in-out';

    });

    carousel?.addEventListener('mouseleave', (e) => {
        e.stopPropagation()
        if (isDragging) {
            const threshold = cardWidth * 0.3;
            if (Math.abs(currentTranslate - prevTranslate) > threshold) {
                if (currentTranslate > prevTranslate) {
                    navigateCarousel('prev', Math.abs(currentTranslate - prevTranslate) / cardWidth);
                } else {
                    navigateCarousel('next', Math.abs(currentTranslate - prevTranslate) / cardWidth);
                }
            }
            carousel.style.transition = 'transform 0.1s ease-in-out';
        }
        isDragging = false;
    });

    function navigateCarousel(direction, multiple) {
        if (direction === 'next') {
            currentTranslate += cardWidth;
            if (currentTranslate > carousel.offsetWidth / 2) currentTranslate = -carousel.offsetWidth;
        } else {
            currentTranslate -= (cardWidth * (multiple));
            if (currentTranslate > -carousel.offsetWidth / 2) {
                currentTranslate = 0
            };
        }
        carousel.style.transform = `translateX(${currentTranslate}px)`;
    }

    // gtag for CarouselScroll
    const throttledHandleCarouselScroll = _.throttle(() => {
        const carouselElem = document.getElementById('carousel-container');
        const carouselWidth = carouselElem?.scrollWidth;
        const halfCarouselWidth = carouselWidth * 0.2;
        const scrollAmount = carouselElem?.scrollLeft;
        if (isScrolled) return;
        if (scrollAmount > halfCarouselWidth) {
            gtagCarouselScroll(product[0].itemId);
            setIsScrolled(true);
            carouselElem?.removeEventListener('scroll', throttledHandleCarouselScroll);
        }
    }, 1000)

    const handleCarouselScroll = () => {
        document.getElementById('carousel-container')?.addEventListener('scroll', throttledHandleCarouselScroll);
    };

    handleCarouselScroll();

    const sendCarouselData = (e, productId, type, idx) => {
        e.stopPropagation();
        const targetWindow = window.parent;
        const productData = {
            productId: productId,
            productType: type,
            productIdx: idx,
        }
        targetWindow.postMessage(productData, "*");
    }

    const handleCarouselClicked = (e, name, idx) => {
        gtagCarouselClick(idx, name, clientId, userGroup);
        gtagClickedOption(id, idx, name, clientId, userGroup);
    }

    return (
        <div id='carousel-container' className={isMobileDevice ? style.Carousel__container__md : style.Carousel__container}>
            <div style={{ position: 'relative' }}>
                <div
                    id='carousel'
                    className={style.Carousel__Hori__flexBox}
                    style={{ '--justify-content': 'flex-start' }}
                >
                    {
                        product?.map((data, idx) => {
                            return (
                                <div key={idx} className={(recommendSizeInput === 'single' && idx > 0) && 'hide'}>
                                    <div
                                        key={idx}
                                        className={style.Carousel__Hori__wrap}
                                        style={{ '--point-color': (idx > 0 ? '#e1e1e1' : primColor), display: ((recommendSizeInput === 'single' && idx > 0) && 'none') }}
                                    >
                                        <div className={style.Carousel__Hori__topWrap}>
                                            <div
                                                className={style.Carousel__Hori__imgWrap}
                                            >
                                                <div
                                                    data-idx={idx}
                                                    className={style.Carousel__Hori__Img}
                                                    style={{
                                                        '--background-image': `url(${data.imageUrl})`,
                                                        '--minWidth': '105px',
                                                        '--minHeight': '140px',
                                                    }}
                                                />
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <a href={isRedirectEnabled ? data.productUrl : null}>
                                                <div 
                                                    className={style.Carousel__contentWrap}
                                                    onClick={(e) => {
                                                        if (!isRedirectEnabled) sendCarouselData(e, data.itemId, data.type, idx)
                                                        handleCarouselClicked(e, data.name, idx)
                                                    }}
                                                >
                                                    <div>
                                                        <div
                                                            className={idx === 0 ? compStyle.Label__badge__recommended : compStyle.Label__badge__related}
                                                            style={{ '--point-color': (idx > 0 ? '#999' : primColor), display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                        >
                                                            <p className='h10'>
                                                                {idx === 0 ? '추천' : '관련'}
                                                            </p>
                                                        </div>
                                                        <div className={compStyle.Spacing__4} />
                                                        <p className='h5'
                                                            style={{
                                                                maxWidth: '141px',
                                                                minHeight: '34px',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 2,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {data.name}
                                                        </p>
                                                    </div>
                                                    <div className={compStyle.Spacing__8} />
                                                    <div
                                                        className={style.Carousel__flexBox}
                                                        style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                    >
                                                        {
                                                            data.discount !== 0 ?
                                                                <div>
                                                                    <p
                                                                        className='h9'
                                                                        style={{ textDecoration: "line-through", color: "#c0c0c0" }}
                                                                    >
                                                                        {addComma(data.originPrice)}
                                                                    </p>
                                                                    <div
                                                                        className={style.Carousel__flexBox}
                                                                        style={{ '--width': '100%', '--align-items': 'flex-start', '--justify-content': 'flex-start' }}
                                                                    >
                                                                        <p className='h1' style={{ color: "#ec3e48" }}> {data.discount}%</p>
                                                                        <div className={compStyle.Spacing__4} />
                                                                        <p className='h1'>{addComma(data.price)}원</p>
                                                                    </div>
                                                                </div> :
                                                                <div>
                                                                    <div style={{ minHeight: '14px' }} />
                                                                    <p className='h1'>
                                                                        {addComma(data.price)}원
                                                                    </p>
                                                                </div>
                                                        }
                                                    </div>
                                                    <div className={compStyle.Spacing__4} />
                                                        {
                                                            clientId === 'viml' ?
                                                            <p className='h9' style={{ color: '#999999' }}>하루 {data.pricePerDay}원 / {data.consumptionDurationInDays}일분</p> :
                                                            <div
                                                                className={style.Carousel__flexBox}
                                                                style={{ '--align-items': 'center', '--height': '15px' }}
                                                            >
                                                                <span style={{ width: '100%', height: '16px', display: 'flex', 'alignItems': 'flex-start' }}>
                                                                    <img src={starFill} width={14} />
                                                                </span>
                                                                <div style={{ minWidth: '2px' }} />
                                                                <p className='h9' style={{ color: '#999999', wordBreak: 'keep-all' }}>
                                                                    {data.rate.toFixed(1) < 0.1 ? '신규' : data.rate.toFixed(1)}
                                                                </p>
                                                            </div>
                                                        }
                                                </div>
                                                <div className={compStyle.Spacing__8} />
                                                <div className={style.Carousel__bottomWrap}>
                                                    <div className={style.Carousel__desc}>
                                                        <p
                                                            className='h8'
                                                            style={{
                                                                color: '#c0c0c0',
                                                                display: '-webkit-box',
                                                                WebkitLineClamp: 1,
                                                                WebkitBoxOrient: 'vertical',
                                                                overflow: 'hidden'
                                                            }}
                                                        >
                                                            {data.shortDesc}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={compStyle.Spacing__8} />
            <div ref={carouselEndRef} />
        </div>
    )
}

export const CarouselCkat = (props) => {
    let { id, product, dialId, isMobileDevice, carouselEndRef, colorInput = undefined, recommendSizeInput = undefined } = props;
    let [isScrolled, setIsScrolled] = useState(false);
    let isDragging = false;
    let startPosition = 0;
    let currentTranslate = 0;
    let prevTranslate = 0;
    const cardWidth = 166;

    const primColor = colorInput ? colorInput.color0 : '#154cca';
    const carousel = document.getElementById('carousel');

    carousel?.addEventListener('mousedown', e => {
        e.stopPropagation()
        isDragging = true;
        startPosition = e.clientX;
        prevTranslate = currentTranslate;
        carousel.style.transition = 'none';
    });

    carousel?.addEventListener('mousemove', e => {
        e.stopPropagation()
        if (isDragging) {
            const currentPosition = e.clientX;
            currentTranslate = prevTranslate + currentPosition - startPosition;
            carousel.style.transform = `translateX(${currentTranslate}px)`;
        }
    });

    carousel?.addEventListener('mouseup', e => {
        e.stopPropagation();
        isDragging = false;
        const threshold = cardWidth * 0.3;
        if (Math.abs(currentTranslate - prevTranslate) > threshold) {
            if (currentTranslate > prevTranslate) {
                navigateCarousel('prev', Math.abs(currentTranslate - prevTranslate) / cardWidth);
            } else {
                navigateCarousel('next', Math.abs(currentTranslate - prevTranslate) / cardWidth);
            }
        }
        carousel.style.transition = 'transform 0.1s ease-in-out';

    });

    carousel?.addEventListener('mouseleave', (e) => {
        e.stopPropagation()
        if (isDragging) {
            const threshold = cardWidth * 0.3;
            if (Math.abs(currentTranslate - prevTranslate) > threshold) {
                if (currentTranslate > prevTranslate) {
                    navigateCarousel('prev', Math.abs(currentTranslate - prevTranslate) / cardWidth);
                } else {
                    navigateCarousel('next', Math.abs(currentTranslate - prevTranslate) / cardWidth);
                }
            }
            carousel.style.transition = 'transform 0.1s ease-in-out';
        }
        isDragging = false;
    });

    function navigateCarousel(direction, multiple) {
        if (direction === 'next') {
            currentTranslate += cardWidth;
            if (currentTranslate > carousel.offsetWidth / 2) currentTranslate = -carousel.offsetWidth;
        } else {
            currentTranslate -= (cardWidth * (multiple));
            if (currentTranslate > -carousel.offsetWidth / 2) {
                currentTranslate = 0
            };
        }
        carousel.style.transform = `translateX(${currentTranslate}px)`;
    }


    // gtag for CarouselScroll
    const throttledHandleCarouselScroll = _.throttle(() => {
        const carouselElem = document.getElementById('carousel-container');
        const carouselWidth = carouselElem?.scrollWidth;
        const halfCarouselWidth = carouselWidth * 0.2;
        const scrollAmount = carouselElem?.scrollLeft;
        if (isScrolled) return;
        if (scrollAmount > halfCarouselWidth) {
            gtagCarouselScroll(product[0].itemId);

            setIsScrolled(true);
            carouselElem?.removeEventListener('scroll', throttledHandleCarouselScroll);
        }
    }, 1000)

    const sendCarouselData = (e, productId, type, idx) => {
        if (isDragging) return;
        e.stopPropagation();
        const targetWindow = window.parent;
        const productData = {
            productId: productId,
            productType: type,
            productIdx: idx,
        }
        targetWindow.postMessage(productData, "*");
    }

    const handleCarouselScroll = () => {
        document.getElementById('carousel-container')?.addEventListener('scroll', throttledHandleCarouselScroll);
    };

    handleCarouselScroll();

    return (
        <div id='carousel-container' className={isMobileDevice ? style.Carousel__container__md : style.Carousel__container}>
            <div style={{ position: 'relative' }}>
                <div
                    id='carousel'
                    className={style.Carousel__flexBox}
                    style={{ '--justify-content': 'flex-start' }}
                >
                    {
                        product?.map((data, idx) => {
                            return (
                                <div key={idx}>
                                    {/* <a href={data.productUrl} style={{width: '100%'}}> */}
                                    <div
                                        key={idx}
                                        className={`${style.Carousel__wrap} carousel-card`}
                                        style={{
                                            '--point-color': (idx > 0 ? 'none' : primColor),
                                            display: ((recommendSizeInput === 'single' && idx > 0) && 'none'),
                                            cursor: 'pointer',
                                        }}
                                        onClick={(e) => sendCarouselData(e, data.itemId, data.type, idx)}
                                    >
                                        <div className={style.Carousel__topWrap}>
                                            <div
                                                className={style.Carousel__imgWrap}
                                                style={{ '--height': '150px' }}
                                            >
                                                <div style={{ position: 'absolute', width: '150px', height: '150px' }}>
                                                    <div style={{ position: 'relative', top: '120px', left: '8px' }}>
                                                        <div
                                                            className={idx === 0 ? compStyle.Label__badge__recommendedFill : compStyle.Label__badge__related}
                                                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                                        >
                                                            <p className='h10'>
                                                                {idx === 0 ? '추천' : '관련'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    data-idx={idx}
                                                    className={style.Carousel__Img}
                                                    style={{
                                                        '--background-image': (`url(${data.imageUrl})`),
                                                        '--width': '150px',
                                                        '--height': '150px',
                                                    }}
                                                />
                                            </div>
                                            <div className={compStyle.Spacing__8} />
                                            <div
                                                className={style.Carousel__contentWrap}
                                                style={{ '--height': '82px' }}
                                            >
                                                <div>
                                                    <div className={compStyle.Spacing__4} />
                                                    <p className='h5'
                                                        style={{
                                                            minHeight: '34px',
                                                            display: '-webkit-box',
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient: 'vertical',
                                                            overflow: 'hidden'
                                                        }}
                                                    >
                                                        {data.name}
                                                    </p>
                                                </div>
                                                <p className='h1'>
                                                    {addComma(data.price)}원
                                                </p>
                                                <div className={compStyle.Spacing__4} />
                                                <div
                                                    className={style.Carousel__flexBox}
                                                    style={{ '--align-items': 'center', '--height': '14px' }}
                                                >
                                                    <img src='/img/ckat/star-400.png' width={14} height={14} />
                                                    <div style={{ minWidth: '2px' }} />
                                                    <p className='h9' style={{ color: '#999999' }}>
                                                        {/* {isNaN(data.rate) ? data.rate : data.rate.toFixed(1)} */}
                                                        후기 {addComma(data.reviewNum)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={compStyle.Spacing__16} />
                                            <div className={style.Carousel__bottomWrap}>
                                                <div
                                                    className={style.Carousel__secondary}
                                                // onClick={() => sendCarouselData(data.type, idx, data.productUrl)}
                                                >
                                                    <p className='h6'>지금 보러가기</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* </a> */}
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={compStyle.Spacing__16} />
            <div ref={carouselEndRef} />
        </div>
    )
}

