import React, { useEffect } from 'react';

function FloatingButtonComp() {
    useEffect(() => {
        console.log('useEffect');
        let floatingButton = null;
        const script = document.createElement('script');
        script.src = "https://dn8zc3bzjh1kn.cloudfront.net/floating-button-sdk.min.js";
        // script.src = "/floating-button-sdk.js";
        script.onload = () => {
            // 스크립트 로드 완료 후 FloatingButton 초기화
            // floatingButton = new window.FloatingButton({clientId: 'mockup', udid: 'test', authCode: 'test', itemId: '3873'});
            // floatingButton.init();
            console.log('onloaded');
            floatingButton = new window.FloatingButton({clientId: 'mockup', udid: 'test', authCode: 'test', itemId: '3873'}); 
            function l() { 
                console.log('initialized');
                if (window.GentooIOInitialized) { return }; 
                window.GentooIOInitialized = true; 
                var sl = () => {handleScroll(sl)};
                document.getElementById('gentoo-sc').addEventListener("scroll", sl);
                window.addEventListener("message", ()=>{});
            }; 
            function handleScroll(sl) {  
                var st = document.getElementById('gentoo-sc').scrollTop + document.getElementById('gentoo-sc').clientHeight;
                var dh = document.getElementById('img-sc').clientHeight;
                var sp = st / dh; 
                if (sp >= 0.6) { 
                    floatingButton.updateParameter({ type: 'needs' })
                    document.getElementById('gentoo-sc').removeEventListener('scroll', sl); 
                } 
            }; 

            if (document.readyState === "complete") { 
                console.log('completed');
                l(); 
            } else { 
                window.addEventListener("DOMContentLoaded", l); 
                window.addEventListener("load", l); 
            };
            // floatingButton.init();
        }

        // window.GentooIO('boot', {
        //     clientId: 'mockup',
        //     udid: 'test',
        //     authCode: 'test',
        //     itemId: '3873',
        // })
        document.body.appendChild(script);
        
        // const targetElem = document.getElementById('gentoo-ref-scroll');
        // const handleScroll = () => {
        //     const scrollTop = targetElem.scrollTop;
        //     const docHeight = targetElem.scrollHeight - targetElem.clientHeight;
            
        //     const scrollPercent = scrollTop / docHeight;
            
        //     // If the scroll position is at 20% of the document height
        //     if (scrollPercent >= 0.6) {
        //         floatingButton.updateParameter({type: 'needs'});
        //         targetElem.removeEventListener('scroll', handleScroll);
        //     }
        // };

        // // Add the scroll event listener
        // targetElem.addEventListener('scroll', handleScroll);

        return () => {
            document.body.removeChild(script);
            // targetElem.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    return <div />;
}

export default FloatingButtonComp;
