import { useParams } from "react-router-dom";
import ChatFieldBaseTest from "../Common/ChatFieldBaseTest";


//COMM: Main Component that contains messages, textfield, etc.
const ChatTest = () => {
    const {demoId} = useParams();

    return (
        <ChatFieldBaseTest
            chatURL={process.env.REACT_APP_DEMO_CHAT_URL}
            resetURL={process.env.REACT_APP_DEMO_RENEW_URL}
            apiKey={process.env.REACT_APP_X_API_KEY_DEMO}
            clientId='demo'
            demoId={demoId}
        />
    );
};

export default ChatTest;