import { useEffect, useState } from "react";
import FloatingButton from "./FloatingButton"
import FloatingButtonComp from "./FloatingButton";
import compStyle from '../style/styles/Components.module.css';

export const MockupDLST = () => {
    let browserWidth = window.innerWidth;
    let [isSmallResolution, setIsSmallResolution] = useState(browserWidth < 601);
    console.log('test');

    window.addEventListener('resize', () => {
        browserWidth = window.innerWidth;
        setIsSmallResolution(browserWidth < 601);
    })

    return (
        <div
            id='gentoo-sc' 
            style={{
                width: '100%',
                height: '100%',
                overflowY: 'scroll',
            }}
        >
            <FloatingButtonComp />
            <img
                id='img-sc'
                src={isSmallResolution ? "/img/dlst-mockup-md.png" : "/img/dlst-mockup-web.png"}
                width={'100%'}
            />
            <div style={{position: 'absolute', bottom: '20px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div 
                    onClick={() => {
                        window.location.href = 'https://dailyshot.co/m/map/seller?item=199694&lat=37.652921&lon=126.892742&seller=16777';
                    }}
                    style={{
                        width: '39%', height: '48px', background: '#FE5000', borderRadius: '8px', color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'
                    }}
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src='/img/units/sdk-pickup-icon.png' width={21} height={18} />
                        <div className={compStyle.Spacing__4} />
                        <p style={{color: '#fff', fontSize: '15px', fontWeight: '900'}}>방문픽업 주문하기</p>
                    </div>
                </div>
            </div>
        </div>
    )
}