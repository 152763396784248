import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

export const ParseCustomSet = () => {
    const navigate = useNavigate();
    const queryString = window.location.search;
    console.log('query string, ', queryString);
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    console.log('searchParams, ', searchParams);
    const email = searchParams.get('email');
    const customSet = {
        logoInput: searchParams.get('logo'),
        nameInput: searchParams.get('name'),
        colorInput: {
            color0: `rgba(${searchParams.get('r0')}, ${searchParams.get('g0')}, ${searchParams.get('b0')}, ${searchParams.get('a0')})`,
            color1: `rgba(${searchParams.get('r1')}, ${searchParams.get('g1')}, ${searchParams.get('b1')}, ${searchParams.get('a1')})`,
            color2: `rgba(${searchParams.get('r2')}, ${searchParams.get('g2')}, ${searchParams.get('b2')}, ${searchParams.get('a2')})`, 
        },
        greetingInput: searchParams.get('greet'),
        recommendSizeInput: searchParams.get('rs'),
        carouselDirInput: searchParams.get('cd'),
        exceptKeyword: [],
        example: [
            searchParams.get('ex0'),
            searchParams.get('ex1'),
            searchParams.get('ex2') !== 'undefined' && searchParams.get('ex2'),
        ]
    }; 
    console.log('customset', customSet);
    localStorage.setItem('customSet', JSON.stringify(customSet));

    window.location.href = `/test/chat/${encodeURIComponent(email)}`;
}