import { useEffect, useState } from 'react';
import style from '../../style/styles/Chat.module.css';
import compStyle from '../../style/styles/Components.module.css';
import { randomText } from '../../utils/utils';
import { useSearchParams } from 'react-router-dom';

const ExampleGroup = (props) => {
    const { clientId, examples, size, handleButtonClick_send, intent = '', variant = 'A', type, isSdk } = props;
    const storedItemId = sessionStorage.getItem(clientId === 'sdk' ? 'itemIdSDK' : 'itemId');

    switch (variant) {
        case 'A':
            return <ExampleGroupA
                examples={clientId === 'dlst' ? examples[0] : examples}
                size={size}
                handleButtonClick_send={handleButtonClick_send}
                intent={intent}
                itemId={storedItemId}
            />
        case 'B':
            return <ExampleGroupB
                examples={clientId === 'dlst' ? examples[0] : examples}
                size={size}
                handleButtonClick_send={handleButtonClick_send}
                type={type}
                intent={intent}
                itemId={storedItemId}
                isSdk={isSdk}
            />
        case 'dlst':
            return <ExampleGroupC
                size={size}
                handleButtonClick_send={handleButtonClick_send}
                intent={intent}
            />
    }
}

const ExampleGroupA = (props) => {
    const { examples, size, handleButtonClick_send, intent, itemId } = props;
    const buttonStyle = (size === 'small') ? compStyle.Button__examStatement__md : compStyle.Button__examStatement;
    const textStyle = 'h3';

    return (
        <div className={style.Chat__exampleWrap}>
            {
                examples.map((example, idx) => {
                    return (
                        <button
                            key={idx}
                            className={`${buttonStyle} example-btn`}
                            onClick={(e) => { handleButtonClick_send(e, example, itemId) }}
                        >
                            <p className={textStyle}>{example}</p>
                        </button>
                    )
                })
            }
        </div>
    )
};

const ExampleGroupB = (props) => {
    const { examples, size, handleButtonClick_send, type, intent, itemId, isSdk } = props;
    const textStyle = 'h3';
    let queryString = window.location.search;
    const [searchParams, setSearchParams] = new useSearchParams(queryString);
    const isMobile = searchParams.get('isMobile') || false;
    const isMobileDevice = (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || isMobile);
    const buttonStyle = (size === 'small' || isMobileDevice) ? compStyle.Button__examStatement__md : compStyle.Button__examStatement;
    const [examLabelArr, setExamLabelArr] = useState([]);
    const [examTextArr, setExamTextArr] = useState([]);

    useEffect(() => {
        randomText(setExamLabelArr, setExamTextArr, type || 'test');
    }, [])

    return (
        <div className={style.Chat__exampleWrap}>
            <button
                className={`${buttonStyle} example-btn`}
                onClick={(e) =>  { handleButtonClick_send(e, examples[0], itemId) }}
            >
                <p className={textStyle}>{examples[0]}</p>
            </button>
                {
                    examples.map((example, idx) => {
                        if (idx !== 0 && idx % 2 === 1) {
                            return (
                                <div 
                                    key={idx} 
                                    className={style.Chat__exampleWrap} 
                                    style={{
                                        flexDirection: 'row', 
                                        flexWrap: 'wrap', 
                                        alignItems: 'flex-end', 
                                        justifyContent: 'flex-end', 
                                        margin: (isMobileDevice || isSdk) && '0 16px 0 16px'
                                    }}>
                                    <button
                                        className={`${buttonStyle} example-btn`}
                                        style={{margin: 0}}
                                        onClick={(e) => { handleButtonClick_send(e, examples[idx], itemId) }}
                                    >
                                        <p className={textStyle}>{examples[idx]}</p>
                                    </button>
                                    <button
                                        className={`${buttonStyle} example-btn`}
                                        style={{margin: 0}}
                                        onClick={(e) => { handleButtonClick_send(e, examples[idx+1], itemId) }}
                                    >
                                        <p className={textStyle}>{examples[idx+1]}</p>
                                    </button>
                                </div>
                            )
                        }
                    })
                }
        </div>
    )
}

const ExampleGroupC = (props) => {
    const { size, handleButtonClick_send, intent } = props;
    const buttonStyle = (size === 'small') ? compStyle.Button__examStatement__md : compStyle.Button__examStatement;
    const textStyle = 'h3';

    const dlstExamShort = [
        '선물로 좋은 5만~10만원 위스키 추천해줘',
        '입문용 위스키',
        '연인과 마실 위스키',
        '고기와 어울리는 레드 와인',
        '달달한 와인',
        '가성비 데일리 술',
        '싱글몰트',
    ]

    return (
        <div className={style.Chat__exampleWrap}>
            <div className={style.Chat__exampleWrap} style={{width: '300px', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-end'}}>
                {
                    dlstExamShort.map((data, idx) => {
                        return (
                            <button
                                key={idx}
                                className={`${buttonStyle} example-btn`}
                                onClick={(e) => { handleButtonClick_send(e, data) }}
                            >
                                <p className={textStyle}>{data}</p>
                            </button>
                        )
                    })
                }
            </div>

        </div>
    )
}

export default ExampleGroup
